<template>
    <div class="home-header">
    <div class=" h-full w-full items-center md:p-16 p-8  text-left md:flex-row flex-col real-content">
      <div data-aos="fade-left" class="flex-one text-center title-section mb-10 ">
        <h6 class="text-xl pb-6 text-center  text-white leading-normal ">Faire passer votre entreprise au niveau supérieur</h6>
        <h3 class="text-white votme-title text-5xl text-center font-bold leading-normal" >Apprenez à modéliser en BPMN grâce à <br/> nos formations et devenez certifié  OCEB 2.0 <br/> grâce à notre accompagnement.</h3>
        <div class="h-8"></div>
        <h6 class="text-xl   text-2xl text-center votme-subtitle text-white leading-normal ">La satisfaction de nos clients est possible grâce à la rapidité et à l'agilité <br/> d'une plateforme véritablement unifiée et d'une équipe de professionnels attentifs.</h6>
        <div class="h-8"></div>
        <div class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4" data-v-74387e92="">
          <a href="#_" class="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-indigo-600 rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
        <router-link to="/formation" class="ml-6 mr-6 text-black text-semibold no-underline"> Savoir plus </router-link>
            </a>
            <a href="#_" class="flex items-center border-solid border-2 border-indigo-600 px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
              <router-link to="/contact" class=""> {{ $t('signIn1') }} </router-link>
            </a>
        </div>
      </div>
      <!--<div data-aos="fade-right" class="girl-section md:w-1/2 w-full h-full md:ml-8 md:mt-0  rounded-tl-full">
        <div class="girl-container h-full w-full">
        </div>
      </div> -->
    </div>
    </div>
    </template>

<script>
export default {
  name: 'ChooseUs',
  components: { }
}
</script>

    <style lang="scss" scoped>
    @import "../../assets/style/sass/variables";

    .home-header  {
      height: 91vh;
      background-color: $primaryBlue;
    }
    .test{
      background-color: $primaryBlue !important;
    }
    .votme-title  {
      font-size: 3rem;
    }

    .votme-subtitle  {
      color: white;
    }
    .girl-section {
      background: linear-gradient(to top right, $primaryBlue, $primaryYellow);
      transition: background-color 4s linear;
      -moz-transition: background-color 4s linear;
      -o-transition: background-color 4s linear;
      -webkit-transition: background-color 4s linear;
      .girl-container {
        overflow-y: hidden;
        background-image: url(../../assets/images/webp/img_girl.webp);
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat;
      }
    }
    @media only screen and (max-width: 600px) {
      .votme-title {
        font-size: 2rem;
      }
      .home-header {
        height: 100%;

        .girl-section {
          height: 18rem;
        }
      }
    }
    </style>
