<template>
    <!------------------OUR PARTNERS------------------>
  <div  data-aos="fade-up" data-aos-duration="3000" class=" lg:mx-40 px-7 lg:rounded-2xl mb-12  bg-white  ">
      <div class="text-2xl leading-10 font-bold text-center  py-8">Nos clients partout dans le monde:</div>
      <div class="lg:flex lg:space-x-12 lg:space-y-0 space-y-8 lg:mx ">
        <div class="flex justify-center space-x-12 ">
          <div><img :src="images.esmt" alt="master" width="400" height="77.68" original></div>
          <div><img :src="images.bdm" alt="intouch" width="380" height="60" original></div>
          <div><img :src="images.bizagi" alt="visa" width="390" height="77.68" original></div>
          <div><img :src="images.flokzu" alt="orabank" width="400" height="80" original></div>
          <div><img :src="images.heaven" alt="mtn" width="390" height="80" original></div>
        </div>
        <div class="flex justify-center space-x-12">
          <div><img :src="images.oo2" alt="master" width="400" height="77.68" original></div>
          <div><img :src="images.hotel" alt="intouch" width="380" height="60" original></div>
          <div><img :src="images.sitcom" alt="visa" width="390" height="77.68" original></div>
        </div>
      </div>
  </div>
</template>

<script>
import esmt from '../../assets/images/webp/esmt.webp'
import hotel from '../../assets/images/webp/hotel.webp'
import bizagi from '../../assets/images/webp/bizagi.webp'
import oo2 from  '../../assets/images/webp/002.webp'
import bdm from  '../../assets/images/webp/bdm.webp'
import flokzu from  '../../assets/images/webp/flokzu.webp'
import heaven from  '../../assets/images/webp/heaven.webp'
import sitcom from  '../../assets/images/webp/sitcom.webp'

export default {
  name: 'LatestCompetitions',
  components: {},
  data () {
    return {
      images: { esmt, hotel, bizagi, oo2, bdm, flokzu, heaven, sitcom }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

</style>
