<template>
  <div class="image-block mt-6 pb-6 text-black">
    <div class="whatWeDo-section pt-64 lg:px-24 md:px-8">
    <div
      class="do-beggin text-center"
      data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
    >
      <div class="do-title text-black text-6xl font-extrabold mt-12">
        <p class="titre relative z-10 w-full text-center">
          {{ $t('whatWeDo') }}
        </p>
         <hr class="separate h-8 relative border-0">
      </div>
      <div class="do-desc font-hairline text-2xl mx-58 leading-relaxed">
        {{ $t('weHelpCompanies') }}
      </div>
    </div>
    <div
      class="do-project mt-12"
      data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
    >
      <div class="they-do flex justify-between">
        <div class="mobile border p-4 max-w-xs mr-4">
          <div class="mobile-title text-2xl font-semibold border-b-4 pb-2">
            {{ $t('mobileDevelopment') }}
          </div>
          <div class="mobile-desc text-xl font-normal mt-8 leading-normal">
            {{ $t('mobileDesc') }}
          </div>
          <div
            class="know-more flex justify-between items-center cursor-pointer mt-6"
            @click="goToMobileDevelopment"
          >
            <div class="arrow border border-black rounded-full p-4 w-12 h-12">
              <svg-icon
                name="ic_fleche"
                class="w-4"
                original
              />
            </div>
            <div class="more-text flex-1 text-xl font-hairline ml-3">
              {{ $t('findMoreBtn') }}
            </div>
          </div>
        </div>
        <div class="mobile border p-4 max-w-xs mr-4">
          <div class="web-title text-2xl font-semibold border-b-4 pb-2">
            {{ $t('webDevelopment') }}
          </div>
          <div class="mobile-desc text-xl font-normal mt-8 leading-normal">
            {{ $t('webDesc') }}
          </div>
          <div
            class="know-more flex justify-between items-center cursor-pointer mt-6"
            @click="goToWebDevelopment"
          >
            <div class="arrow border border-black rounded-full p-4 w-12 h-12">
              <svg-icon
                name="ic_fleche"
                class="w-4"
                original
              />
            </div>
            <div class="more-text flex-1 text-xl font-hairline ml-3">
              {{ $t('findMoreBtn') }}
            </div>
          </div>
        </div>
        <div class="mobile border p-4 max-w-xs mr-4">
          <div class="design-title text-2xl font-semibold border-b-4 pb-2">
            {{ $t('designSprint') }}
          </div>
          <div class="mobile-desc text-xl font-normal mt-8 leading-normal">
            {{ $t('designDesc') }}
          </div>
          <div
            class="know-more flex justify-between items-center cursor-pointer mt-6"
            @click="goToDesignSprint"
          >
            <div class="arrow border border-black rounded-full p-4 w-12 h-12">
              <svg-icon
                name="ic_fleche"
                class="w-4"
                original
              />
            </div>
            <div class="more-text flex-1 text-xl font-hairline ml-3">
              {{ $t('findMoreBtn') }}
            </div>
          </div>
        </div>
        <div class="mobile border p-4 max-w-xs">
          <div class="digital-title text-2xl font-semibold border-b-4 pb-2">
            {{ $t('digitalTransformation') }}
          </div>
          <div class="mobile-desc text-xl font-normal mt-8 leading-normal">
            {{ $t('digitalDesc') }}
          </div>
          <div
            class="know-more flex justify-between items-center cursor-pointer mt-6"
            @click="goToDigitalTransformation"
          >
            <div class="arrow border border-black rounded-full p-4 w-12 h-12">
              <svg-icon
                name="ic_fleche"
                class="w-4"
                original
              />
            </div>
            <div class="more-text flex-1 text-xl font-hairline ml-3">
              {{ $t('findMoreBtn') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWork',
  components: {},
  methods: {
    goToWebDevelopment () {
      this.$router.push({ path: '/automatisation' })
    },
    goToMobileDevelopment () {
      this.$router.push({ path: '/accompagnement' })
    },
    goToDesignSprint () {
      this.$router.push({ path: '/modelisation' })
    },
    goToDigitalTransformation () {
      this.$router.push({ path: '/formation' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";
.right-section {
  background-color: #F5F8FF;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  padding-top: 22rem;
}

.image-block{
  background-color: #F5F8FF;
}
.parent-container {
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem
}
.details-block {
  background-color: white;
  background-image: url(../../assets/images/webp/main.webp);
  z-index: 9999;
  margin-bottom: 20rem !important;
  margin-left: 25%;
  width: auto;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  padding-top: 4rem;
  //  box-shadow: rgba(163, 177, 198, 0.5) 2px 2px 4px 0px, rgb(255, 255, 255) -2px -2px 4px 0px;
  // -webkit-box-shadow: rgba(163, 177, 198, 0.5) 2px 2px 4px 0px, rgb(255, 255, 255) -2px -2px 4px 0px;
}
.create-account {
  background-color: $primaryYellow;
  color: white;
  // width: 40%;
}
.how-subtitle {
  color: white;
}
@media only screen and (max-width: 600px) {
  .details-block {
    margin-left: .8rem;
    width: 95%;
  }
}
.separate {
    background-color: $devs_main-color;
    width: 30rem;
    left: 28rem;
    top: -2rem;
  }
  .do-desc {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .mobile-title {
    color: $devs_main-color;
    border-bottom-color: $devs_main-color;
    width: max-content;
  }
  .web-title {
    color: $devs_green;
    border-bottom-color: $devs_green;
    width: max-content;
  }
  .design-title {
    color:#607D8B !important;
    border-bottom-color: #607D8B !important;
    width: max-content;
  }
  .digital-title {
    color: $devs_orange;
    border-bottom-color: $devs_orange;
    width: max-content;
  }
  @media screen and (max-width: 600px) {
    .whatWeDo-section {
      width: 100%;
      overflow: hidden;
      padding-top: 25rem;
    }
    .do-desc {
      margin-right: 0;
      margin-left: 0;
      text-align: left;
    }
    .do-title {
      font-size: 4rem;
      margin-bottom: 2rem;
    }
    .titre {
      position: static;
    }
    .separate {
      display: none;
    }
    .they-do {
      display: block;
      text-align: left;
    }
    .mobile {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .whatWeDo-section {
      padding: 0 1rem 2rem;
      width: 100%;
      overflow: hidden;
    }
    .do-desc {
      margin-right: 0;
      margin-left: 0;
      text-align: left;
    }
    .do-title {
      font-size: 4rem;
      margin-bottom: 2rem;
    }
    .titre {
      position: static;
    }
    .separate {
      display: none;
    }
    .they-do {
      display: block;
      text-align: left;
    }
    .mobile {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .whatWeDo-section {
      padding-top: 0;
      padding-bottom: 3rem;
      width: 100%;
      overflow: hidden;
    }
    .separate {
      display: none;
    }
    .titre {
      position: static;
    }
    .do-desc {
      margin-right: 0;
      margin-left: 0;
    }
    .do-title {
      margin-bottom: 3rem;
    }
    .they-do {
      flex-wrap: wrap;
    }
    .mobile {
      margin-right: 1rem;
      max-width: 48%;
      margin-bottom: 1rem;
    }
  }
</style>
