<template>
<div data-aos="fade-up" data-aos-duration="3000" class="home-header">
<div class=" h-full w-full items-center md:p-16 p-8  text-left md:flex-row flex-col real-content">
  <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" class="flex-one text-center title-section mb-10 ">
    <h6 data-aos="fade-up"
     data-aos-anchor-placement="center-bottom" class="text-xl pb-6 text-center  text-primaryBlue leading-normal ">Faire passer votre entreprise au niveau supérieur</h6>
    <h3 data-aos="fade-up"
     data-aos-anchor-placement="center-bottom" class="text-black votme-title text-5xl text-center font-bold leading-normal" v-html="$t('createTitle')"></h3>
    <div class="h-8"></div>
    <h6 data-aos="fade-up"
     data-aos-anchor-placement="center-bottom" class="text-xl font-semi  text-2xl text-center votme-subtitle text-black leading-normal "  v-html=" $t('createSubtitle')"></h6>
    <div class="h-8"></div>
    <div data-aos="fade-up"
     data-aos-anchor-placement="center-bottom" class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4" data-v-74387e92="">
      <router-link to="/team" class="">
      <a href="#_" class="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-indigo-600 rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
        Savoir plus        
            </a>
          </router-link>
          <router-link to="/contact" class="">
            <a href="#_" class="flex items-center border-solid border-2 border-indigo-600 px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
              {{ $t('signIn1') }}            </a>
          </router-link>
    </div>
  </div>
  <!--<div data-aos="fade-right" class="girl-section md:w-1/2 w-full h-full md:ml-8 md:mt-0  rounded-tl-full">
    <div class="girl-container h-full w-full">
    </div>
  </div> -->
</div>
</div>
</template>

<script>
export default {
  name: 'HomeHeader',
  components: { }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.home-header  {
  height: 91vh;
  background-color: #F7F9FE;
}
.test{
  background-color: $primaryBlue !important;
}
.votme-title  {
  font-size: 3rem;
}

.votme-subtitle  {
  color: $greyText;
}
.girl-section {
  background: linear-gradient(to top right, $primaryBlue, $primaryYellow);
  transition: background-color 4s linear;
  -moz-transition: background-color 4s linear;
  -o-transition: background-color 4s linear;
  -webkit-transition: background-color 4s linear;
  .girl-container {
    overflow-y: hidden;
    background-image: url(../../assets/images/webp/img_girl.webp);
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
  }
}
@media only screen and (max-width: 600px) {
  .votme-title {
    font-size: 2rem;
  }
  .home-header {
    height: 100%;

    .girl-section {
      height: 18rem;
    }
  }
}
</style>
