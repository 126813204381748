<template>
   <!-- Section 3 -->
 <section class="w-full bg-white pt-7 pb-7 md:pt-20 md:pb-24">
    <div class="top">
        <div class="box-border w-full flex flex-col items-center content-center mb-8 px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-0 md:flex-row  lg:px-16">

<!-- Content -->
<div data-aos="fade-right-up" data-aos-duration="3000" class="w-full  box-border w-full text-black border-solid md:w-1/2 md:pl-6 xl:pl-32">
    <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
      Cartographiez et modélisez vos processus de façon graphique
    </h2>
    <p class="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
      Grâce à nos outils graphiques de modélisation de processus, nous vous accompagnons dans la Cartographie et modélisation de vos processus par un simple glisser-déposer basée sur du <a href="http://bpmn.org">BPMN</a>, un standard mondialement reconnu    </p>
    <div class="flex-one text-right  h-16 items-center mt-4">
      <router-link to="/contact" class="">  <CreateButton label="signIn1"></CreateButton> </router-link>
    </div>
</div>
<!-- End  Content -->

<!-- Image -->
<div data-aos="fade-left-up" 
     data-aos-duration="3000" class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
    <img :src="images.proces" alt="app" class="pl-4 sm:pr-10 xl:pl-10 lg:pr-32 lg:w-609px" original>
</div>
</div>
<div class="w-full  box-border flex flex-col items-center content-center mb-16 mt-20 px-8 mx-auto leading-6 text-black border-0 border-gray-300 border-solid md:flex-row  lg:px-16">

<!-- Image -->
<div data-aos="fade-left-up" 
     data-aos-duration="3000" class="box-border relative w-full max-w-md px-4 mt-5 mb-4 -ml-5 text-center bg-no-repeat bg-contain border-solid md:ml-0 md:mt-0 md:max-w-none lg:mb-0 md:w-1/2 xl:pl-10">
<img :src="images.process" alt="app" class="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 lg:w-609px " original>
</div>

<!-- Content -->
<div data-aos="fade-right-up" data-aos-duration="3000" class="box-border order-first w-full text-black border-solid md:w-1/2 md:pl-10 md:order-none">
<h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
Aller au-delà de la modelisation, digitalisez vos processus.
</h2>
<p class="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-12 xl:pr-32 lg:text-lg">
  Avec nos solutions low-code (très peu de développement), nous vous proposons des applications pour supporter vos processus métiers et faciliter leur suivi, la génération et le suivi d'indicateurs en temps-réel. Et ceci en temps record et plus rapidement que le feraient un développeurs classiques.</p>
<div class="flex-one text-right  h-16 items-center mt-4">
  <router-link to="/contact" class="">  <CreateButton label="signIn1"></CreateButton> </router-link>
</div>
</div>
<!-- End  Content -->

</div>
<div data-aos="fade-left" class="details-block md:w-1/3 p-6  flex flex-col  justify-center items-center shadow rounded-lg absolute">
      <h3 class="text-4xl text-white text-center font-bold">Nous aidons votre entreprise à se <br> développer plus rapidement. </h3>
      <p class="mt-10 text-xl text-white  text-center how-subtitle leading-relaxed">Commencez  aujourd'hui votre voyage vers l'amélioration <br> de vos processus métier. </p>
      <div class="mt-10">
        <div class="mobile-button cursor-pointer flex-two items-center text-center mb-2">
          <router-link to="/contact" class="">  <CreateButton label="signIn1"></CreateButton> </router-link>
        </div>
      </div>
    </div>
    </div>
</section>
</template>

<script>
import CreateButton from '../helpers/CreateButton.vue'
import proces from '../../assets/images/webp/process.webp'
import process from '../../assets/images/webp/proces.webp'
export default {
  name: 'ProcesHome',
  components: { CreateButton },
  data () {
    return {
      images: { proces, process }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";
.parent-container {
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem
}
.details-block {
  background-color: white;
  background-image: url(../../assets/images/webp/main.webp);
  z-index: 9999;
  margin-bottom: 20rem !important;
  margin-left: 30%;
  width: auto;
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  padding-top: 4rem;
  //  box-shadow: rgba(163, 177, 198, 0.5) 2px 2px 4px 0px, rgb(255, 255, 255) -2px -2px 4px 0px;
  // -webkit-box-shadow: rgba(163, 177, 198, 0.5) 2px 2px 4px 0px, rgb(255, 255, 255) -2px -2px 4px 0px;
}
.create-account {
  background-color: $primaryYellow;
  color: white;
  // width: 40%;
}
.how-subtitle {
  color: white;
}
@media only screen and (max-width: 600px) {
  .details-block {
    margin-left: .8rem;
    width: 95%;
  }
}

</style>
